/* eslint-disable default-case */
import { Ajax, Browser } from '@syncfusion/ej2-base';
import $ from 'jquery';
import _ from 'underscore/underscore-min';
import axios from 'axios';
import * as Loader from "react-loader-spinner";
import pako from "pako";
import { Buffer } from "buffer";

let qs = require('qs');

export const APIJfishTechURLSvil = 'http://localhost:3005';
export const APIJfishTechURLTest = 'https://apicallertest.digitalpraesidium.it';
export const APIJfishTechURLProd = 'https://apicaller.digitalpraesidium.it';
export const APIJfishTechURLSvil2 = 'http://localhost:3010';
export const APIJfishTechURLTest2 = 'https://apicallertest2.digitalpraesidium.it';  
export const APIJfishTechURLProd2 = 'https://apicaller2.digitalpraesidium.it';  
export const APIVariousJfishTechURLSvil = 'https://localhost:5001';
export const APIVariousJfishTechURLTest = 'https://apicallertest3.digitalpraesidium.it';  
export const APIVariousJfishTechURLProd = 'https://apicaller3.digitalpraesidium.it'; 
export const tokenAuthAPIVarious = 'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNjY0NDYzNiwiaWF0IjoxNjM2NjQ0NjM2fQ.uZHXED8JKuGWPJfjaiHzBjhlsHQhhWjeUaVqMyF5YIQ';
export const URLApplicazioneSvil = 'http://localhost:5020';
export const URLApplicazioneTest = 'http://62.149.181.213:5020';
export const URLApplicazioneProd = 'https://tech.jfish.it';  
export const CartellaTemporaneaUpload = 'CartellaTempDocsIncarichiCAT';
export const APIURLTokenUnipolSAICert = "https://mpgw-coll.unipol.it";
export const APIURLTokenUnipolSAIProd = "https://mpgw.unipol.it";
export const NomeCertificatoUnipolSAI = "07643030963";
export const APIURLDatiUnipolSAICert = "https://syugbovapigw-coll.unipol.it";
export const APIURLDatiUnipolSAIProd = "https://syugvapigw.unipol.it";
export const PathURLDatiUnipolSAICert = "/unipolsai/coll-publ";
export const PathURLDatiUnipolSAIProd = "/unipolsai/prod-publ";
export const ClientIdCertificazioneUnipolSAI = "3faa8b3d-26ad-45b1-a193-19b96487513d";
export const ClientIdProduzioneUnipolSAI = "1f1e4bfc-3df7-48eb-8e59-91880a33dc19";
export const ClientSecretCertificazioneUnipolSAI = "tB3qW5pH3wL6yD2qE1hR3tC1vJ1jL1nG6jN8iV6sH2tC4fW2wL";
export const ClientSecretProduzioneUnipolSAI = "Q1eD5uP3bF4fU7oF8cN3xW1oL3eK2uQ8bH4tK2tP3tI2nP1rC2";
export const codiceCompagniaUnipolSAI = '3';
export const codiceCompagniaGenerali = '5';
export const messaggioNoBackend = 'Attenzione!! Nessuna comunicazione attiva con il backend. Riprovare nuovamente l\'operazione e se il problema persiste, contattare il servizio di supporto Digital Praesidium';
export const messaggioAPIBackendNonTrovata = 'Attenzione!! Comunicazione attiva con il backend ma API non trovata. Contattare il servizio di supporto Digital Praesidium.';
export const messaggioUtenteNonAutorizzato = 'Attenzione!! Utente non autorizzato alle operazioni di Jellyfish CAT Mobile. Prima di effettuare qualsiasi operazione è necessario accedere al sistema!!';
export const messaggioSessioneScaduta = 'Attenzione!! La sessione di collegamento è scaduta (durata massima prevista di 8 ore). Uscire dal sistema ed accedere nuovamente per continuare le operazioni!!';
export const messaggioErroreAggiornamentoJFTECH = `ATTENZIONE!! Gli aggiornamenti lato sede ACT (Jellyfish Technology) per l'incarico <span style='font-size:15px;font-weight:bolder'>{0}</span> NON sono andati a buon fine. Riprovare più tardi ad inviare nuovamente l'incarico in sede ACT e se il problema persiste, contattare la sede ACT quanto prima per segnalare la problematica`;
export const messaggioConfermaUscita = 'Tutti i dati non salvati andranno persi. Vuoi continuare?';
export const messaggioConfermaFirmaCliente = 'Confermi di voler acquisire la firma di fine lavori del cliente?';
export const messaggioErroreObbligo = 'Non è possibile proseguire con il salvataggio in quanto alcuni dei campi obbligatori non sono stati compilati.';
export const messaggioArchiviazioneIncarichi = 'Attenzione!! Per evitare il riempimento eccessivo dello spazio di archiviazione temporaneo sul server utilizzato dall\'applicazione, ricordarsi per favore di archiviare periodicamente gli incarichi più vecchi e/o non più necessari per la consultazione della documentazione inviata ad ACT. Grazie';
export const widthScreenResolution = window.screen.width * window.devicePixelRatio;
export const heightScreenResolution = window.screen.height * window.devicePixelRatio;
export const chartWidth = Browser.isDevice ? '92%' : '96%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '85%' :  '80%' : Browser.userAgent.indexOf('Firefox') > -1 ? '105%' :  '110%'; 
export const chartWidth2 = Browser.isDevice ? '92%' : '96%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '115%' :  '120%' : Browser.userAgent.indexOf('Firefox') > -1 ? '95%' :  '100%';
export const chartWidth3 = Browser.isDevice ? '92%' : '96%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '129%' :  '136%' : Browser.userAgent.indexOf('Firefox') > -1 ? '109%' :  '116%';
let chartHeightTemp = Browser.userAgent.indexOf('Firefox') > -1 ? '580' : Browser.userAgent.indexOf('Edg') > -1 ? '585' : Browser.userAgent.indexOf('OPR') > -1 ? '590' : '590';
let chartHeightTemp2 = Browser.userAgent.indexOf('Firefox') > -1 ? '755' : Browser.userAgent.indexOf('Edg') > -1 ? '760' : Browser.userAgent.indexOf('OPR') > -1 ? '760' : '760';
export const chartHeight = (parseInt(chartHeightTemp) - ((1080 - heightScreenResolution) === 0 ? 0 : (1080 - heightScreenResolution) < 0 ? (1080 - heightScreenResolution) : (1080 - heightScreenResolution + 50))).toString();
export const chartHeight2 = (parseInt(chartHeightTemp2) - ((1080 - heightScreenResolution) === 0 ? 0 : (1080 - heightScreenResolution) < 0 ? (1080 - heightScreenResolution) : (1080 - heightScreenResolution + 50))).toString();
export const colorsData = [
    { Color: 'AliceBlue', Code: '#F0F8FF' },
    { Color: 'Aqua', Code: '#00FFFF' },
    { Color: 'Aquamarine', Code: '#7FFFD4' },
    { Color: 'Beige', Code: '#F5F5DC' },
    { Color: 'BurlyWood', Code: '#DEB887' },
    { Color: 'Chartreuse', Code: '#7FFF00' },
    { Color: 'Coral', Code: '#FF7F50' },
    { Color: 'Cornsilk', Code: '#FFF8DC' },
    { Color: 'LightSalmon', Code: '#FFA07A' },
    { Color: 'DarkTurquoise', Code: '#00CED1' },
    { Color: 'DodgerBlue', Code: '#1E90FF' },
    { Color: 'Gainsboro', Code: '#DCDCDC' },
    { Color: 'Gold', Code: '#FFD700' },
    { Color: 'GreenYellow', Code: '#ADFF2F' },
    { Color: 'Khaki', Code: '#F0E68C' },
    { Color: 'Lavender', Code: '#E6E6FA' },
    { Color: 'LawnGreen', Code: '#7CFC00' },
    { Color: 'LightBlue', Code: '#ADD8E6' },
    { Color: 'MediumSpringGreen', Code: '#00FA9A' },
    { Color: 'MistyRose', Code: '#FFE4E1' },
    { Color: 'Plum', Code: '#DDA0DD' },
    { Color: 'SandyBrown', Code: '#F4A460' },
    { Color: 'Thistle', Code: '#D8BFD8' },
    { Color: 'Turquoise', Code: '#40E0D0' },
    { Color: 'Yellow', Code: '#FFFF00' },
    { Color: 'Tomato', Code: '#FF745C' },
    { Color: 'Violet', Code: '#EE82EE' },
];
export const loaderSpinners = [<Loader.Audio width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.BallTriangle width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Bars width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Blocks width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Circles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.CirclesWithBar width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.ColorRing width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Comment width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.Discuss width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, //<Loader.Dna width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.FallingLines width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.FidgetSpinner width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Grid width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.InfinitySpin width={250} height={250} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.LineWave width={250} height={250} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.MagnifyingGlass width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.MutatingDots width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Oval width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.ProgressBar width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Puff width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Radio width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RevolvingDot width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Rings width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RotatingLines width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.RotatingSquare width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RotatingTriangles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.TailSpin width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.ThreeCircles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.ThreeDots width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Triangle width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Vortex width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}/>, <Loader.Watch width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>];

export async function getRemoteData(url, data, type, header) { //Chiamata con oggetti Syncfusion - Non funziona...dà errore a livello di CORS
    let ajax = new Ajax({
        url: url,
        type: type,
        mode: false,
        data: data,
        setRequestHeader: header,
        onFailure: (e) => { return false; }
    });

    const response = await ajax.send();
    let retData = JSON.parse(response);
    return { response: retData['response'], retData: retData['data'] };
}

export async function getRemoteData2(url, paramData, type, header, cbFunction, async, test) {  //Chiamata Javascript puro XHR - Funziona bene
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = false;

    xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
            if (test) {
                cbFunction(this.responseText);
            }
            else {
                let retData = JSON.parse(this.responseText);
                console.log(this.responseText);
                if (retData['response'].cod === 0)
                    cbFunction(retData['data']);
                else
                    console.log(retData['response'].message);
            }
        }
    });

    xhr.open(type, url);
    xhr.setRequestHeader("Content-Type", header);
    xhr.async = async;

    xhr.send(paramData);
}

export async function getRemoteData3(url, paramData, type, header, cbFunction, async, test) {  //Chiamata Jquery - Funziona bene
    let settings = {
        "url": url,
        "method": type,
        "timeout": 0,
        "headers": header,
        "data": paramData,
        "async": async
    };

    $.ajax(settings).done((response) => {
        if (test) {
            cbFunction(response);
        }
        else {
            let retData = JSON.parse(response);
            console.log(response);
            if (retData['response'].cod === 0)
                cbFunction(retData['data']);
            else
                console.log(retData['response'].message);
        }
    }).fail((response) => {
        console.log(response);
    });
}

export async function getRemoteData4(url, paramData, type, header, cbFunction, async, test) {  //Chiamata Axios - Funziona bene (speriamo)
    let data = qs.stringify(paramData);
    let config = {
        method: type,
        url: url,
        headers: header,
        data: data
    };

    axios(config)
        .then(response => {
            debugger;
            if (test) {
                cbFunction(response.data);
            }
            else {
                let retData = response.data;
                console.log(JSON.stringify(response.data));
                if (retData['response'].cod === 0)
                    cbFunction(retData['data']);
                else
                    console.log(retData['response'].message);
            }
        })
        .catch(error => {
            debugger;
            console.log(error);
        });
}

export async function callAPI(url, paramData, type, header, headerAuth, cbFunctionOK, cbFunctionKO, async, test) {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
  
    xhr.onerror = function(err){
      debugger;
      cbFunctionKO(err);
    };
  
    xhr.addEventListener("readystatechange", function() {
      if (this.readyState === 4 && this.status === 200) {
        if (test) {
          cbFunctionOK(this.responseText);
        }
        else {
          debugger;
          let retData = isNullEmpty(this.responseText) ? [] : JSON.parse(this.responseText);        
          cbFunctionOK(retData);        
        }           
      }
      else if (this.readyState === 4 && this.status === 404)
          cbFunctionKO(this.responseText + this.statusText);
    });
    
    xhr.open(type, url);
    xhr.setRequestHeader("Content-Type", header);
    xhr.setRequestHeader("Authorization", headerAuth);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "http://localhost:3000");
    xhr.async = async; 
  
    if (!isNullEmpty(paramData))
        xhr.send(paramData);  
    else
        xhr.send();
}

export async function eseguiQuery(URL, token, tipo, QueryExec, paramData, cbFunctionOK, cbFunctionKO) {  
    let header = tipo === 'GET' ? '' : 'application/json';
    QueryExec = encodeURIComponent(QueryExec);
    callAPI(`${URL}/api/JellyfishTechnology/eseguiQuery?QueryExec=${QueryExec}`, paramData, tipo,
        header, token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? messaggioSessioneScaduta : retData.msg.message;
                cbFunctionKO(messaggio);
                return;
            }   
            cbFunctionOK(retData);
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            cbFunctionKO(messaggio);
    }, true, false)
}

export async function caricaCombo(URL, token, CodDiv, CodSoc, CodFil, CodTab, Lingua, InserisciRigaBlank, cbFunctionOK, cbFunctionKO) {
    callAPI(`${URL}/api/JellyfishTech/caricaCombo/${CodDiv}/${CodSoc}/${CodFil}/${CodTab}/${Lingua}/${InserisciRigaBlank}`, '', 'GET',
    '', token, retData => { 
        if (retData.response === 'KO') {
            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? messaggioSessioneScaduta : retData.msg;
            cbFunctionKO(messaggio);
            return;
        }   
        cbFunctionOK(retData);
    }, error => {
        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
        cbFunctionKO(messaggio);
    }, true, false)
}

export async function getProgressivo(URL, token, CodDiv, CodSoc, CodFil, TipoProgr, DatoRif, GestAnno, cbFunctionOK, cbFunctionKO) {
    callAPI(`${URL}/api/JellyfishTech/getProgressivo/${CodDiv}/${CodSoc}/${CodFil}/${TipoProgr}/${DatoRif}/${GestAnno}`, '', 'GET',
    '', token, retData => { 
        if (retData.response === 'KO') {
            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? messaggioSessioneScaduta : retData.msg;
            cbFunctionKO(messaggio);
            return;
        }   
        cbFunctionOK(retData);
    }, error => {
        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
        cbFunctionKO(messaggio);
    }, true, false)
}

export function handleMouseMove(event) {
    var eventDoc, doc, body;

    event = event || window.event; // IE-ism

    // If pageX/Y aren't available and clientX/Y are,
    // calculate pageX/Y - logic taken from jQuery.
    // (This is to support old IE)
    if (event.pageX == null && event.clientX != null) {
        eventDoc = (event.target && event.target.ownerDocument) || document;
        doc = eventDoc.documentElement;
        body = eventDoc.body;

        event.pageX = event.clientX +
            (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
            (doc && doc.clientLeft || body && body.clientLeft || 0);
        event.pageY = event.clientY +
            (doc && doc.scrollTop || body && body.scrollTop || 0) -
            (doc && doc.clientTop || body && body.clientTop || 0);
    }

    //console.log ('X: ' + event.pageX + ' - Y:' + event.pageY);

    //if (document.getElementById('TabMonitor').ej2_instances[0].selected<Index === 0 && event.pageX >= 680 && event.pageX <= 735 && event.pageY >= 620 && event.pageY <= 635) 
}

export function leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

export function isNullEmpty(valore, multiArray) {
    try {
        if (multiArray) {
            var _wRet = false;
            for (var i = 0; i < valore.length; i++) {
                if (isNullEmpty(valore[i])) {
                    _wRet = true;
                    break;
                }
            }
            return _wRet;
        } else {
            switch (true) {
                case valore === null:
                    return true;
                    break;
                case Object.prototype.toString.call(valore) === '[object Date]':
                    return false;
                    break;
                case typeof valore === 'undefined':
                case typeof valore === 'null':
                    return true
                    break;
                case typeof valore === 'string':
                    if (valore.toString().trim() == "null" || valore.toString().trim() == "undefined") {
                        return true;
                    } else if (valore.toString().trim().length > 0) {
                        return false;
                    } else {
                        return true;
                    }
                    break;
                case typeof valore === 'boolean':
                    return false;
                    break;
                case typeof valore === 'number':
                    return false;
                    break;
                case typeof valore === 'object':
                    if (Object.keys(valore).length > 0) {
                        return false;
                    } else {
                        return true;
                    }
                    break;
            }
        }

    }
    catch (ex) {
        return true;
    }
}

export function setInterval_(fn, delay) {
    var maxDelay = Math.pow(2, 31) - 1;

    if (delay > maxDelay) {
        var args = arguments;
        args[1] -= maxDelay;

        return setInterval(function () {
            setInterval_.apply(undefined, args);
        }, maxDelay);
    }

    return setInterval.apply(undefined, arguments);
}

//http://byronsalau.com/blog/how-to-create-a-guid-uuid-in-javascript/  
export function createGuid4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}  

export function makeID(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
} 

export function checkInvalidChars(txtValue, pattern) { 
    if (_.isUndefined(pattern))   
        pattern = /^[0-9a-zA-Z]+$/;
    return (txtValue.value?.trim().match(pattern))
}

export function base64Encode(str) {
    var CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    var out = "", i = 0, len = str.length, c1, c2, c3;
    while (i < len) {
        c1 = str.charCodeAt(i++) & 0xff;
        if (i === len) {
            out += CHARS.charAt(c1 >> 2);
            out += CHARS.charAt((c1 & 0x3) << 4);
            out += "==";
            break;
        }
        c2 = str.charCodeAt(i++);
        if (i === len) {
            out += CHARS.charAt(c1 >> 2);
            out += CHARS.charAt(((c1 & 0x3)<< 4) | ((c2 & 0xF0) >> 4));
            out += CHARS.charAt((c2 & 0xF) << 2);
            out += "=";
            break;
        }
        c3 = str.charCodeAt(i++);
        out += CHARS.charAt(c1 >> 2);
        out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
        out += CHARS.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >> 6));
        out += CHARS.charAt(c3 & 0x3F);
    }
    return out;
}

export function getBinary(file){
    var xhr = new XMLHttpRequest();
    xhr.open("GET", file, false);
    xhr.overrideMimeType("text/plain; charset=x-user-defined");
    xhr.send(null);
    return xhr.responseText;
}

export function decodeAndUncompress(base64String) {
    if (isBase64Encoded(base64String) === true)
      return pako.inflate(Buffer.from(base64String, "base64"), { to: "string" });
    else return base64String;
  }
  
  export function compressAndEncode(obj) {
    return Buffer.from(pako.deflate(JSON.stringify(obj))).toString("base64");
  }
  
  function isBase64Encoded(str) {
    try {
      return Buffer.from(str, "base64").toString("base64") === str;
    } catch (error) {
      return false;
    }
  }  