/* eslint-disable jsx-a11y/iframe-has-title */
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, CommandColumn, Edit, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { PdfViewerComponent, Toolbar as PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject as PDFVInject } from '@syncfusion/ej2-react-pdfviewer';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import * as CustomJS from '../Custom';
import { SampleBase } from './sampleBase';
import _ from 'underscore/underscore-min';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import ReactPlayer from 'react-player';
//import DialogUploader from './DialogUploader';
import { DashboardModal } from '@uppy/react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import Webcam from '@uppy/webcam';
import ImageEditor from '@uppy/image-editor';
import Italiano from '@uppy/locales/lib/it_IT';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import BlockUi from '@availity/block-ui';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { random, isUndefined } from 'underscore';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

let GridData = [], ddlData2 = [];

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '1.4vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {       
    let colore = props.column.field === 'NomeDocumento' ? '#133B95' : 'purple';   
    let renderDiv = (props.column.field === 'NuovoCanc' && props.Stato === 'N') ? <div style={{ paddingTop: '7px' }}><span style={{ fontSize: '1.7vw', color: 'green', fontWeight: 'bolder' }}>N</span></div> 
                    : (props.column.field === 'NuovoCanc' && props.Stato === 'D') ? <div style={{ paddingTop: '7px' }}><span style={{ fontSize: '1.7vw', color: 'darkred', fontWeight: 'bolder' }}>C</span></div> 
                    : <div><span style={{ fontSize: '1.7vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (renderDiv);    
}
export default class GridDocumentazione extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);        
        this.editSettings = { showDeleteConfirmDialog: false, allowAdding: true, allowDeleting: true, allowEditing: true, mode: 'Dialog'};
        this.sortSettings = { columns: [{ field: 'NomeDocumento', direction: 'Ascending' }] };
        this.toolbarOptions = ['Add', { template: this.ddlCartelleToolbar.bind(this) },  { template: this.txNuovaCartellaToolbar.bind(this) }];        
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Vedi Documento", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash-alt fa-10x", cssClass: "e-details" } },
                         { type: "Ripristina Documento", buttonOption: { iconCss: "fas fa-undo fa-10x", cssClass: "e-details" } }];
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.uppy = new Uppy({ locale: Italiano, 
                               restrictions: {
                                    maxTotalFileSize: 1073741824,
                                    maxFileSize: 524288000 
                               }
                            })
            .use(XHRUpload, { method: 'POST', formData: true,  fieldName: 'documenti', bundle: true, timeout: 0,
                              headers: { "Authorization": JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).token }, 
                              endpoint: `${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/uploadDocumenti/JFTECH/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].UTENTE}/${document.getElementById('IdIncarico').ej2_instances[0].value?.trim()}` 
                            })
            .use(Webcam, { 
                onBeforeSnapshot: () => Promise.resolve(),
                countdown: false, 
                modes: ['video-audio', 'video-only', 'audio-only', 'picture'],
                videoConstraints: {                    
                    width: { min: 720, ideal: 1920, max: 1920 },
                    height: { min: 480, ideal: 1080, max: 1080 },
                  },
                showRecordingLength: true,
                showVideoSourceDropdown: true,
                preferredVideoMimeType: 'video/mp4',
                preferredImageMimeType: 'image/png'
            })
            .use(ImageEditor, {               
                quality: 0.8,
                cropperOptions: {
                  viewMode: 1,
                  background: false,
                  autoCropArea: 1,
                  responsive: true
                },
                actions: {
                  revert: true,
                  rotate: true,
                  flip: true,
                  zoomIn: true,
                  zoomOut: true,
                  cropSquare: true,
                  cropWidescreen: true,
                  cropWidescreenVertical: true
                }
              })
            .on('upload-success', (file, response) => {  
                             
            })
            .on('complete', (result) => {
                result.successful.forEach((elm,idx) => {
                    let objDoc = {
                        Guid: elm.response.body[idx].filename.substring(0, 36),
                        GuidRiferimento: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico,                   
                        NomeDocumento: elm.response.body[idx].originalname,
                        CartellaDocumento: CustomJS.isNullEmpty(document.getElementById('txtNuovaCartella').ej2_instances[0].value?.trim()) ? document.getElementById('ddlCartelle').ej2_instances[0].value : document.getElementById('txtNuovaCartella').ej2_instances[0].value?.trim(),
                        Path: elm.response.body[idx].filename,
                        CaricatoDaUtente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].GUID_UTENTE,
                        CaricatoDaUtenteNome: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].RAGIONE_SOCIALE,
                        Stato: 'N',
                        Stato_nuovo: 'N',
                        Letto: 'OD'
                    };
                    this.gridInstance1.dataSource.push(objDoc);
                    let IdIncarico = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico === 'NEW' ? document.getElementById('IdIncarico').ej2_instances[0].value?.trim() : JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).IdIncarico; 
                    this.lightboxImages.push({ url: `${CustomJS.URLApplicazioneProd}/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].UTENTE}/${IdIncarico}/${objDoc.Path}`,
                                               title: objDoc.NomeDocumento});
                });                 
                this.gridInstance1.refresh(); 
                if (!CustomJS.isNullEmpty(this.txtInstance.value?.trim())) {   
                    this.ddlData.push({ VALUE: this.txtInstance.value?.trim(), TEXT: this.txtInstance.value?.trim() });                         
                    this.setState({ddlData: this.ddlData});
                    this.ddlInstance.refresh();
                }
                this.txtInstance.value = null; 
            })        
        this.handleClose = this.handleClose.bind(this);
        this.childKey = 0;
        this.ddlData = [];
        this.dialogButtons1 = [{
            click: () => {
                this.dialogInstance2.hide();
                this.setState({
                    showUppy: true
                });
                //Esempio di richiamo uploader Syncfusion con preview immagini
                // this.dialogInstance3.header = 'CARICAMENTO DOCUMENTI (TEMPORANEO)';    
                // this.dialogInstance3.width = '65%';
                // this.dialogInstance3.height = '19vh';
                // this.dialogInstance3.minHeight = '23vh';
                // this.dialogInstance3.buttons = [{
                //     click: () => {
                //         this.dialogInstance3.hide();
                //     },
                //     buttonModel: {
                //         content: 'ANNULLA',
                //     }
                // }];            
                // this.dialogInstance3.open = (args) => {
                //     const saveURL = `${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/uploadDocumenti/JFTECH/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].UTENTE}/${document.getElementById('IdIncarico').ej2_instances[0].value?.trim()}`;
                //     const removeURL = `${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/uploadDocumenti/JFTECH/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].UTENTE}/${document.getElementById('IdIncarico').ej2_instances[0].value?.trim()}`;
                //     ReactDOM.render(<DialogUploader key={++this.childKey} saveURL={saveURL} removeURL={removeURL} lightboxImages={this.lightboxImages} ddlData={this.ddlData} allowedExtensions='.jpg,.png,.jpeg,.pdf,.doc,.docx,.xls,.xlsx'></DialogUploader>, document.getElementById('defaultDialogDoc3Content'), () => { 
                //     });     
                // }
                // this.dialogInstance3.close = (args) => {
                //     document.getElementById('txtNuovaCartella').ej2_instances[0].value = null; 
                // }
                // this.dialogInstance3.show(); 
            },
            buttonModel: {
                content: 'CONFERMA',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance2.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];        
        this.lightboxImages = [];
        this.lightboxImage = '';
    }    
    state = {
        showDialog1: false,
        showDialog2: false,
        showDialog3: false,
        showUppy: false,
        GridData2: GridData,
        ddlData: this.ddlData,
        showLightbox1: false,
        showLightbox2: false,
        blocking: false,
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }   
    handleClose() {
        this.setState({
            showUppy: false
        })
    }
    onQueryCellInfo(args) {       
    }
    onDatabound(args) {
        for (let ind = 0; ind <= 4; ind++)
            document.getElementById('GridDocumentazione').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }
    onRowDatabound(args) { 
        if (args.data.Stato === 'N') {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightgreen';
        }    
        if (args.data.Stato === 'D') {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightcoral';  
        }
        if (args.data.Stato === 'D') {
            $($(args.row).find('button')[3]).show();
            $($(args.row).find('button')[2]).hide();
        }
        else {
            $($(args.row).find('button')[3]).hide();
            $($(args.row).find('button')[2]).show();
        }
        if (args.data.Visualizzabile === 'S'
            || _.find(JSON.parse(sessionStorage.getItem('JellyfishTechMobileTabellaEXT')), (obj) => { return obj.VALUE === args.data.Path.substr(args.data.Path.indexOf('.')) })?.SERVIZIO_STR4 === 'S') {
            $($(args.row).find('button')[1]).show();
        }
        else {
            $($(args.row).find('button')[1]).hide();            
        }    
        if (JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'INV') {
            $($(args.row).find('button')[0]).hide();
            $($(args.row).find('button')[2]).hide();
        }    
    }    
    onActionComplete(args) {
        if (args.requestType === "delete") {
            var objDoc = args.data[0];
            objDoc.Stato = 'D';
            document.getElementById('GridDocumentazione').ej2_instances[0].dataSource.push(objDoc);
            document.getElementById('GridDocumentazione').ej2_instances[0].refresh();
        }
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-undo') > -1) {            
            if (!CustomJS.isNullEmpty(args.rowData.Stato_nuovo))
                args.rowData.Stato = args.rowData.Stato_nuovo;
            else
                args.rowData.Stato = 'O';
            this.gridInstance1.refresh();
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-eye') > -1) {  
            let CartellaDocumento = args.rowData.CartellaDocumento === 'Cartella Principale' ? '' : `/${args.rowData.CartellaDocumento}`;
            let IdIncarico = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico === 'NEW' ? document.getElementById('IdIncarico').ej2_instances[0].value?.trim() : JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).IdIncarico; 
            let URLDocumento = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'DAINV' ? `${CustomJS.URLApplicazioneProd}/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].UTENTE}/${IdIncarico}/${args.rowData.Path}`
                               : `${CustomJS.URLApplicazioneProd}/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].UTENTE}/${IdIncarico}${CartellaDocumento}/${args.rowData.NomeDocumento}`; 
            if (args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "AVI" || args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "MP4"
                || args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "WMV" || args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "MOV"
                || args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "MKV" || args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "WEBM") {                                  
                $('#VideoPlayer').show(); 
                $("#iframeVisualDoc").attr("src", "");
                $("#iframeVisualDoc").hide();
                $('#PDFViewer').hide();
                this.dialogInstance1.width = '60%';
                this.dialogInstance1.height = '65%';
                this.dialogInstance1.open = (args) => {
                    const container = document.getElementById('VideoPlayer');
                    const root = createRoot(container); 
                    root.render(this.videoViewerRender(URLDocumento));   
                };
                this.dialogInstance1.close = (args) => {
                    const container = document.getElementById('VideoPlayer');
                    const root = createRoot(container); 
                    root.unmount();
                };
                this.dialogInstance1.show();
            }
            else if (args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "PDF") {
                $('#VideoPlayer').hide();                   
                $("#iframeVisualDoc").attr("src", "");
                $("#iframeVisualDoc").hide();
                $('#PDFViewer').show();
                ++this.childKey;
                this.dialogInstance3.header = 'VISUALIZZAZIONE DOCUMENTO PDF';
                this.dialogInstance3.width = '65%';
                this.dialogInstance3.height = '90%';
                this.dialogInstance3.close = undefined;
                this.dialogInstance3.beforeOpen = (args) => {
                    const container = document.getElementById('defaultDialogDoc3Content');
                    const root = createRoot(container); 
                    root.unmount();
                };
                this.dialogInstance3.open = (args) => {
                    const container = document.getElementById('defaultDialogDoc3Content');
                    const root = createRoot(container); 
                    root.render(<CallbackWrapper callback={() => {
                        document.getElementById('PDFViewerComp').ej2_instances[0].enableDownload = false;
                        document.getElementById('PDFViewerComp').ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                        this.toggleBlocking();
                        setTimeout(() => {
                            const binDocPDF = CustomJS.getBinary(URLDocumento);
                            const base64PDF = CustomJS.base64Encode(binDocPDF);
                            document.getElementById('PDFViewerComp').ej2_instances[0].load(`data:application/pdf;base64,${base64PDF}`, null);
                            this.toggleBlocking();
                        }, 400);
                    }}>{this.PDFViewerRender()}</CallbackWrapper>);
                };
                this.dialogInstance3.show();
            }
            else if (args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "XLS"
                     || args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "XLSX"
                     || args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "DOCX"
                     || args.rowData.Path.substring(args.rowData.Path.lastIndexOf(".") + 1).toUpperCase() === "DOC") {
                const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(URLDocumento)}&embedded=true`;
                const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(URLDocumento)}`;
                $("#iframeVisualDoc").attr("src", "");
                $("#iframeVisualDoc").attr("src", URLVisualOffice365);
                $("#iframeVisualDoc").show();
                $('#PDFViewer').hide();
                $('#VideoPlayer').hide(); 
                this.dialogInstance1.width = '60%';
                this.dialogInstance1.open = undefined;
                this.dialogInstance1.close = undefined;
                this.dialogInstance1.show();    
            }
            else {
                this.lightboxImage = URLDocumento;
                this.setState({showLightbox2: true});
            }
        }
    }  
    toolbarClick(args) {
        if (args.item.id === 'GridDocumentazione_add') {
            args.cancel = true;   
            if (!CustomJS.isNullEmpty(_.find(this.ddlInstance.dataSource, elm => { return elm.VALUE === this.txtInstance.value?.trim() }))) {
                let messaggio = 'La nuova cartella è già esistente, selezionarla nel menù a tendina a fianco.'
                toast.warn(messaggio, {
                    containerId: 'toastContainer3',
                    onClose: () => toast.dismiss(),                    
                    position: "right-center",
                    autoClose: 5000,
                    hideProgressBar: false,            
                    closeOnClick: true, 
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                return;
            }            
            if (!CustomJS.isNullEmpty(this.txtInstance.value?.trim()) && !CustomJS.checkInvalidChars(this.txtInstance, /^[0-9a-zA-Z ]+$/)) {            
                let messaggio = 'Il nome della nuova cartella contiene caratteri invalidi. Sono permessi unicamente caratteri alfanumerici e spazi.'
                toast.warn(messaggio, {
                    containerId: 'toastContainer3',
                    onClose: () => toast.dismiss(),                    
                    position: "right-center",
                    autoClose: 5000,
                    hideProgressBar: false,            
                    closeOnClick: true, 
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                return;
            }
            if (!CustomJS.isNullEmpty(this.txtInstance.value?.trim())) {
                this.dialogInstance2.content = `I documenti verranno caricati nella nuova cartella <span style='font-size:18px;font-weight:bolder'>${this.txtInstance.value.trim()}</span>. Assicurarsi che il nome della nuova cartella sia quello corretto in quanto una volta effettuato l'upload dei documenti non sarà più possibile cambiarlo ma sarà necessario invece effettuare un nuovo caricamento nella cartella con il nome corretto`;
                this.dialogInstance2.width = '35%';
                this.dialogInstance2.height = '240px';
                this.dialogInstance2.minHeight = '240px';
                this.dialogInstance2.show();
            }
            else {
                this.setState({
                    showUppy: true
                });
                //Esempio di richiamo uploader Syncfusion con preview immagini
                // this.dialogInstance3.header = 'CARICAMENTO DOCUMENTI (TEMPORANEO)';    
                // this.dialogInstance3.width = '65%';
                // this.dialogInstance3.height = '19vh';
                // this.dialogInstance3.minHeight = '23vh';
                // this.dialogInstance3.buttons = [{
                //     click: () => {
                //         this.dialogInstance3.hide();
                //     },
                //     buttonModel: {
                //         content: 'ANNULLA',
                //     }
                // }];            
                // this.dialogInstance3.open = (args) => {
                //     const saveURL = `${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/uploadDocumenti/JFTECH/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].UTENTE}/${document.getElementById('IdIncarico').ej2_instances[0].value?.trim()}`;
                //     const removeURL = `${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/uploadDocumenti/JFTECH/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].UTENTE}/${document.getElementById('IdIncarico').ej2_instances[0].value?.trim()}`;
                //     ReactDOM.render(<DialogUploader key={++this.childKey} saveURL={saveURL} removeURL={removeURL} lightboxImages={this.lightboxImages} ddlData={this.ddlData} allowedExtensions='.jpg,.png,.jpeg,.pdf,.doc,.docx,.xls,.xlsx'></DialogUploader>, document.getElementById('defaultDialogDoc3Content'), () => { 
                //     });     
                // }
                // this.dialogInstance3.close = (args) => {
                //     document.getElementById('txtNuovaCartella').ej2_instances[0].value = null; 
                // }
                // this.dialogInstance3.show();     
            }
        }
    }
    ddlCartelleToolbar(args) {                
        return (
            <DropDownListComponent id='ddlCartelle' fields={this.ddlFields} index='0' ref={ ddlObj => this.ddlInstance = ddlObj } dataSource={this.state.ddlData} allowFiltering={false} filterType='Contains' readonly={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'INV'} 
                style={{width: '40vw'}} placeholder="Selezionare una cartella dove effettuare l'upload dei documenti" floatLabelType='always' popupHeight="250px" cssClass='e-outline'/>
       );        
    }
    txNuovaCartellaToolbar(args) {                
        return (
            <TextBoxComponent id='txtNuovaCartella' showClearButton={true} value='' ref={ txtObj => this.txtInstance = txtObj } 
                readonly={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'INV'} floatLabelType='Auto' style={{width: '37vw'}} 
                placeholder="Inserire nuova cartella dove effettuare l'upload dei documenti" cssClass='e-outline'></TextBoxComponent>
       );        
    }
    onCreated(args) {
        setTimeout(() => {
            if (JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'INV') {
                this.gridInstance1.toolbarModule.enableItems(['GridDocumentazione_add'], false);
                this.ddlInstance.enabled = false;
                this.txtInstance.enabled = false;
            }
        }, 200);       
    }
    onClickGalleriaImmagini() {      
        this.setState({showLightbox1: true});
    }
    PDFViewerRender() {
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <PdfViewerComponent id='PDFViewerComp' key={this.childKey} width="auto" style={{ 'height': '720px' }} 
                                                    enablePrint={true} enableDownload={true} enableAnnotation={false} enableCommentPanel={false}
                                                    serviceUrl={`${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`}>
                                                <PDFVInject services={[PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]} />
                                                </PdfViewerComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }
    videoViewerRender(URLVideo) {
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">
                                                <ReactPlayer key={this.childKey} url={URLVideo} controls={true} stopOnUnmount={true} playing={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }

    render() {
        return(<React.Fragment>
                    <BlockUi id='blockUIDocs' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                        <div className="control-section card-control-section basic_card_layout">
                            <ToastContainer enableMultiContainer containerId='toastContainer3' transition={Zoom} style={{ fontSize: '1.3vw', color: 'midnightblue', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                            <DashboardModal uppy={this.uppy} width='40%' height='40%' closeModalOnClickOutside open={this.state.showUppy} onRequestClose={this.handleClose} plugins={['Webcam', 'ImageEditor']} />
                            <div>{this.state.showLightbox1 ? <Lightbox images={this.lightboxImages} onClose={()=>this.setState({showLightbox1: false})}/>:null}</div>                 
                            <div>{this.state.showLightbox2 ? <Lightbox image={this.lightboxImage} onClose={()=>this.setState({showLightbox2: false})}/>:null}</div>                 
                            <DialogComponent id="defaultDialogDoc1" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} width='90%'
                                            ref={dialog => this.dialogInstance1 = dialog} height='85%' minHeight='90%' isModal={true}>
                                    <div className="col-lg-12 control-section card-control-section basic_card_layout">
                                        <div className="e-card-resize-container">
                                            <div className="card-layout">
                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                    <div tabIndex="0" className="e-card" id="main_card">
                                                        <div className="e-card-header">
                                                            <div className="e-card-header-caption">
                                                                <div className="e-card-header-title">Visualizzazione documento</div>
                                                            </div>
                                                        </div>
                                                        <div className="e-card-content">
                                                            <iframe id="iframeVisualDoc" width="100%" height="680vh" src="" frameBorder="0"></iframe>
                                                            <div id='VideoPlayer' style={{ width: '300%', height: 'auto' }}></div>
                                                            <div id='PDFViewer' style={{ width: '100%', height: 'auto' }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </DialogComponent>
                            <DialogComponent id="defaultDialogDoc2" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog2} width='35%'
                                    ref={dialog => this.dialogInstance2 = dialog} header='Attenzione!!' buttons={this.dialogButtons1} height='210px' minHeight='210px' isModal={true} 
                                    content="">
                            </DialogComponent>
                            <DialogComponent id="defaultDialogDoc3" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog3} 
                                    ref={dialog => this.dialogInstance3 = dialog} isModal={true} height='90%' width='90%' minHeight='90%'>   
                                    <div id='defaultDialogDoc3Content'></div>                         
                            </DialogComponent> 
                            <div className="e-card-resize-container">
                                <div className="card-layout">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">                                
                                        <div tabIndex="0" className="e-card" id="main_card">
                                            <div className="e-card-header">
                                                <div className="e-card-header-caption">
                                                    <span style={{ fontSize: '3.0vw', fontWeight: 'bolder', paddingLeft: '5px', color:'#133B95'}}>DOCUMENTAZIONE INCARICO</span>
                                                </div>
                                            </div>
                                                <div className="e-card-content"> 
                                                <div className="row">
                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 text-end'>
                                                        <ProgressButtonComponent id='btnGalleriaImmagini' content="Galleria immagini" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                                                            animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-warning" onClick={this.onClickGalleriaImmagini.bind(this)}>
                                                        </ProgressButtonComponent> 
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>                 
                                                        <GridComponent id="GridDocumentazione" dataSource={this.state.GridData2} ref={ grid => this.gridInstance1 = grid } toolbarClick={this.toolbarClick.bind(this)} created= {this.onCreated.bind(this)}
                                                            editSettings={this.editSettings} sortSettings={this.sortSettings} queryCellInfo={this.onQueryCellInfo.bind(this)} actionComplete={this.onActionComplete.bind(this)}
                                                            allowSelection={true} enableHover={false} dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} commandClick={this.onCommandClick.bind(this)}
                                                            allowTextWrap={true} allowPaging={false} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false} toolbar={this.toolbarOptions}>
                                                            <ColumnsDirective>
                                                                <ColumnDirective field='Guid' visible={false} isPrimaryKey={true}></ColumnDirective>
                                                                <ColumnDirective field='NomeDocumento' headerText='Nome Documento' width='60%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                                <ColumnDirective field='CartellaDocumento' headerText='Cartella' width='15%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Left' allowEditing={false} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                                <ColumnDirective field='NuovoCanc' headerText="" width='5%' template={fieldTemplate} textAlign='Center' allowEditing={false}></ColumnDirective>
                                                                <ColumnDirective field='Comandi' headerText="" commands={this.commands} width='20%' textAlign='Center'></ColumnDirective>
                                                            </ColumnsDirective>
                                                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Edit, CommandColumn, Sort]} />
                                                        </GridComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BlockUi>
            </React.Fragment>);
    }
    componentDidMount() {
        caricaDati(this);  
    }
    componentWillUnmount () {
        this.uppy.close();
    }
}

function caricaDati(thisClone) {
    const GuidIncarico = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico;
    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/caricaListeIncarico/${GuidIncarico}/DOCS`, '', 'GET',
    '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).token, retData => { 
        if (retData.response === 'KO') {
            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
            toast.warn(messaggio, {
                containerId: 'toastContainer3',
                onClose: () => toast.dismiss(),                    
                position: "right-center",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true, 
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            return;
        }               
        GridData = [];
        retData.forEach(elm => {           
            GridData.push({ Guid: elm.GUID, TipoDoc: elm.TIPO_DOCUMENTO, GuidRiferimento: elm.GUID_RIFERIMENTO, NomeDocumento: elm.NOME_DOCUMENTO, Stato: 'O',
                           Visualizzabile: elm.VISUALIZZABILE, Path: elm.PATH_DOCUMENTO, CartellaDocumento: elm.CARTELLA_DOCUMENTO, CaricatoDaUtente: elm.CARICATO_DA_UTENTE, CaricatoDaUtenteNome: elm.NOMINATIVO,
                           DataOraCaricamento: elm.DATA_ORA_CARICAMENTO, Letto: elm.LETTO});
            if (elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "AVI" && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "MP4"
               && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "WMV" && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "MOV"
               && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "MKV" && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "WEBM"
               && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "PDF" && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "DOC"
               && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "DOCX" && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "XLS"
               && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "XLSX") {
                let CartellaDocumento = elm.CARTELLA_DOCUMENTO === 'Cartella Principale' ? '' : `/${elm.CARTELLA_DOCUMENTO}`;
                let IdIncarico = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico === 'NEW' ? document.getElementById('IdIncarico').ej2_instances[0].value?.trim() : JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).IdIncarico; 
                thisClone.lightboxImages.push({ url: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'DAINV' ? `${CustomJS.URLApplicazioneProd}/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].UTENTE}/${IdIncarico}/${elm.PATH_DOCUMENTO}`
                                                    : `${CustomJS.URLApplicazioneProd}/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload'))).data[0].UTENTE}/${IdIncarico}${CartellaDocumento}/${elm.NOME_DOCUMENTO}`,
                                                title: elm.NOME_DOCUMENTO});
            }
        });
        ddlData2 = _.groupBy(GridData, 'CartellaDocumento');
        thisClone.ddlData.push({ VALUE: 'Cartella Principale', TEXT: 'Cartella Principale (Sopralluogo)' });
        Object.keys(ddlData2).forEach(elm => {
            if (elm !== 'Cartella Principale')
                thisClone.ddlData.push({ VALUE: elm, TEXT: elm });
        });
        thisClone.setState({ GridData2: GridData });        
        setTimeout(() => {
            thisClone.ddlInstance.dataSource = thisClone.ddlData;
            thisClone.ddlInstance.value = 'Cartella Principale';  
        }, 300);         
    }, error => {
        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
        toast.warn(messaggio, {
            containerId: 'toastContainer3',
            onClose: () => toast.dismiss(),                    
            position: "right-center",
            autoClose: 15000,
            hideProgressBar: false,            
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true});        
    }, true, false)
}